import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getAuthData, clearAuthData, saveAuthData } from '../../helpers/auth';
import actions from './actions';
import { signInTypes } from '../../settings';
import { reduxSagaFirebase, firebase } from '../firestore';
import { callApi } from '../../helpers/api';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* loginRequest({ payload }) {
    const { type, email, password, token } = payload;
    let result;
    let authProvider;

    switch (type) {
      case signInTypes.GOOGLE:
        authProvider = new firebase.auth.GoogleAuthProvider();
        result = yield call(reduxSagaFirebase.auth.signInWithPopup, authProvider);
        break;
      case signInTypes.FACEBOOK:
        authProvider = new firebase.auth.FacebookAuthProvider();
        result = yield call(reduxSagaFirebase.auth.signInWithPopup, authProvider);
        break;
      case signInTypes.TOKEN:
        result = yield call(reduxSagaFirebase.auth.signInWithCustomToken, token);
        break;
      default:
        try {
          result = yield call(reduxSagaFirebase.auth.signInWithEmailAndPassword, email, password);
        } catch (e) {
          yield put(
            actions.loginError({
              error:
                e.message !== 'The user account has been disabled by an administrator.'
                  ? e.message
                  : "This email hasn't been registered or verified yet. Please go to the SIGN-UP page if you are not registered.  Or look for the verification email in your inbox and click the link in that email If you’re already signed up and haven’t verified your email.",
            }),
          );
        }
        break;
    }

    if (result) {
      let ExtractedAccessToken;
      let ExtractedIdToken;
      if (type === signInTypes.EMAIL || type === signInTypes.TOKEN) {
        const idTokenRes = yield result.user.getIdTokenResult();
        ExtractedIdToken = idTokenRes.token;
        ExtractedAccessToken = null;
      } else {
        const { idToken, accessToken } = result;

        ExtractedIdToken = idToken;
        ExtractedAccessToken = accessToken;
      }

      saveAuthData({
        idToken: ExtractedIdToken,
        accessToken: ExtractedAccessToken,
      });
    }
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* logout() {
    yield call(reduxSagaFirebase.auth.signOut);
    clearAuthData();
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* checkAuthorization() {
    const authData = getAuthData();
    if (authData) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        payload: { ...authData },
      });
    }
  });
}

export function* updateSsoCookieAndLink() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* updateSsoCookie() {
    const result = yield callApi('users/sso-link', 'GET');
    yield put(actions.updateSsoLink(result.data));
  });
}

export function* slideIn() {
  yield takeEvery(actions.SLIDE_IN, function* slideIn({ payload }) {
    yield callApi('users/slide-in', 'POST', payload);
    yield put(push('/'));
  });
}

export function* slideOut() {
  yield takeEvery(actions.SLIDE_OUT, function* slideOut() {
    yield callApi('users/slide-out', 'POST');
    yield put(push('/'));
  });
}

export function* logRequest() {
  yield takeEvery(actions.LOG_REQUEST, function*({ payload }) {
    yield callApi(`users/log-verification-data/${payload.token}`, 'POST', payload);
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(logout),
    fork(updateSsoCookieAndLink),
    fork(slideIn),
    fork(slideOut),
    fork(logRequest),
  ]);
}
